<template>
  <v-card class="pa-4" flat>
    <v-row>
      <v-col cols="12" sm="12">
        <v-tabs v-model="tab" center-active color="primary" @change="tabValue">
          <v-tab>
            {{ $t("e_test.tabs.first") }}
          </v-tab>
          <v-tab>
            {{ $t("e_test.tabs.second") }}
          </v-tab>
          <v-tab>
            {{ $t("e_test.tabs.third") }}
          </v-tab>
          <v-tab>
            {{ $t("e_test.tabs.fourth") }}
          </v-tab>
        </v-tabs>
      </v-col>
      <!-- <v-col cols="12" sm="2">
        <span class="font-weight-bold subtitle-1">
          {{ $t("e_test.good_signal") }}
        </span>
        <v-badge color="lime accent-3" class="ml-5" />
      </v-col> -->
    </v-row>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <Table
          :tab="numberTab"
          :dataTable="dataTable"
          :table="table"
          @paginateChange="paginateChange"
          @sort="sort"
        />
      </v-tab-item>
      <v-tab-item>
        <Table
          :tab="numberTab"
          :dataTable="dataTable"
          :table="table"
          @paginateChange="paginateChange"
          @sort="sort"
        />
      </v-tab-item>
      <v-tab-item>
        <Table
          :tab="numberTab"
          :dataTable="dataTable"
          :table="table"
          @paginateChange="paginateChange"
          @sort="sort"
        />
      </v-tab-item>
      <v-tab-item>
        <Table
          :tab="numberTab"
          :dataTable="dataTable"
          :table="table"
          @paginateChange="paginateChange"
          @sort="sort"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { listEtestStudent } from "@/api/admin/academic/eTest";

export default {
  metaInfo: {
    title: "E-Test",
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Table: () => import("./Table")
  },
  data() {
    return {
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false,
        sort: [],
        order: ""
      },
      bodyRequest: {
        tab: ""
      },
      dataTable: {
        data: []
      },
      listEtest: [],
      tab: parseInt(this.$route.query.tab)
    };
  },
  created() {
    const tab = this.numberTab;
    if (Number.isInteger(tab)) this.setParamTab(tab);
    this.getListEtest();
  },
  computed: {
    numberTab() {
      return parseInt(this.$route.query.tab);
    }
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) {
        this.tab = parseInt(this.$route.query.tab);
      } else {
        this.tab = 0;
      }
      this.setParamTab(n);
      this.getListEtest();
    }
  },
  methods: {
    sort(item) {
      this.table.sort = [item.sort];
      this.table.order = item.order;
      this.getListEtest();
    },
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getListEtest();
    },
    setParamTab(tab) {
      switch (parseInt(tab)) {
        case 0:
          this.bodyRequest.tab = "";
          break;
        case 1:
          this.bodyRequest.tab = "berlangsung";
          break;
        case 2:
          this.bodyRequest.tab = "tersedia";
          break;
        case 3:
          this.bodyRequest.tab = "akan_datang";
          break;
      }
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    async getListEtest() {
      this.table.loading = true;
      this.dataTable = { data: [] };
      try {
        const response = await listEtestStudent({
          ...this.table,
          parameter: this.bodyRequest.tab
        });
        if (response.data.code) {
          this.dataTable = response.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.table.loading = false;
    },
    tabValue(tab) {
      this.$router.push({ name: "EtestStudent", query: { tab: tab } });
    }
  }
};
</script>
